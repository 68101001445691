.full-size-flex {
  height: 100%;
  width: 100%;
  display: flex;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-spaced-columns {
  @extend .flex-column;
  justify-content: space-between;
}
